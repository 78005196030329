import React, { useState } from 'react';
import './SplashScreen.css';

const SplashScreen = ({ onClose }) => {
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = React.useRef(null);

  const toggleAudio = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="unique-splash-screen">
      <video ref={videoRef} className="unique-splash-video" autoPlay muted>
        <source src="/videotrailer.webm" type="video/webm" />
        Your browser does not support the video.
      </video>

      <button className="unique-audio-toggle-button" onClick={toggleAudio}>
        {isMuted ? '🔇' : '🔊'}
      </button>

      <button className="unique-splash-close-button" onClick={onClose}>
        Entra
      </button>
    </div>
  );
};

export default SplashScreen;
