import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

// Modale per i commenti
const CommentManager = ({ rectangleId, isOpen, onClose }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(5); // Limite di caricamento per commenti lazy
  const [currentParentId, setCurrentParentId] = useState(null);

  // Funzione per caricare i commenti
  const fetchComments = async (parentCommentId = null) => {
    if (!rectangleId) return;
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/unique-comments/${rectangleId}/${parentCommentId || 'null'}/${limit}`);
  
      if (response.data) {
        if (parentCommentId) {
          setComments((prevComments) =>
            prevComments.map((comment) =>
              comment.comment_id === parentCommentId ? { ...comment, replies: response.data } : comment
            )
          );
        } else {
          setComments(response.data);
        }
      }
    } catch (error) {
      console.error('Errore durante il caricamento dei commenti:', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (isOpen && rectangleId) {
      fetchComments(); // Carica i commenti principali
    }
  }, [isOpen, rectangleId, limit]);

  // Funzione per gestire l'aggiunta di un nuovo commento
  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/unique-comments`, {
        rectangle_id: rectangleId,
        content: newComment,
        user_id: localStorage.getItem('userId'),
        parent_comment_id: currentParentId ? currentParentId : null,
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      setComments((prevComments) => currentParentId ? 
        prevComments.map((comment) =>
          comment.comment_id === currentParentId ? 
            { ...comment, replies: [...(comment.replies || []), response.data] } : comment
        ) : [...prevComments, response.data]);
      setNewComment('');
      setCurrentParentId(null);
    } catch (error) {
      console.error('Errore durante l\'inserimento del commento:', error);
    } finally {
      setLoading(false);
    }
  };
  

  // Funzione per gestire la risposta a un commento
  const handleReply = (commentId) => {
    setCurrentParentId(commentId);
    setNewComment(`@${commentId} `); // Placeholder per identificare la risposta
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="rect-comm-modal" overlayClassName="rect-comm-overlay">
      <h2>Commenti</h2>
      <div className="comments-container">
        {comments.map((comment) => (
          <div key={comment.comment_id} className="comment">
            <p>{comment.content}</p>
            {comment.replies ? (
              comment.replies.map((reply) => (
                <div key={reply.comment_id} className="reply">
                  <p><strong>Risposta:</strong> {reply.content}</p>
                </div>
              ))
            ) : (
              <button onClick={() => fetchComments(comment.comment_id)}>Mostra Risposte</button>
            )}
            <button onClick={() => handleReply(comment.comment_id)}>Rispondi</button>
          </div>
        ))}
        {loading && <p>Caricamento...</p>}
      </div>
      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Scrivi un commento..."
      />
      <button onClick={handleAddComment} disabled={loading}>Invia</button>
      <button onClick={onClose} disabled={loading}>Chiudi</button>
      <button onClick={() => setLimit(limit + 5)} disabled={loading}>Carica altri</button>
    </Modal>
  );
};

// Aggiungi le classi CSS per evitare conflitti con altri stili
Modal.defaultStyles.overlay.zIndex = '1000'; // Imposta un valore di z-index elevato

export default CommentManager;
