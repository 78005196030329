import React, { useState } from 'react';
import Modal from 'react-modal';
import './SecondSplashScreen.css'; // Stile CSS per il secondo splash screen

const SecondSplashScreen = ({ onClose }) => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  // Gestione del cambio di stato della checkbox
  const handleCheckboxChange = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  // Funzione per aprire la modale delle condizioni
  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  // Funzione per chiudere la modale delle condizioni
  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  return (
    <div className="unique-splash-screen-2">
      <div className="unique-splash-content-2">
        {/* Checkbox per accettare le condizioni di utilizzo */}
        <div className="terms-checkbox-container">
          <input 
            type="checkbox" 
            checked={acceptedTerms} 
            onChange={handleCheckboxChange} 
            id="terms-checkbox"
          />
          <label htmlFor="terms-checkbox" className="terms-label">
            Ho letto e accetto le <span className="terms-link" onClick={openTermsModal}>Condizioni di Utilizzo</span>
          </label>
        </div>
        <h1 className="unique-splash-title-2">Benvenuto su MicroWavePocalypse!</h1>
        <p className="unique-splash-text-2">
          Questo sito è un'esperienza interattiva unica, una mappa mentale che si arricchisce grazie al contributo degli utenti.
          Al centro trovi il primo capitolo della storia, scritto da <strong>Yelena Vostrikov</strong>, una delle protagoniste
          dell'universo horror sci-fi Apollo Nephilim.
        </p>
        <h2 className="unique-splash-subtitle-2">Come funziona?</h2>
        <ul className="unique-splash-list-2">
          <li>
            Ogni utente può proporre la propria idea per continuare il racconto, che verrà poi rielaborata dall'intelligenza
            artificiale per mantenere la coerenza narrativa.
          </li>
          <li>
            Ogni contributo aggiungerà un nuovo nodo alla mappa, segnando il nome dell'utente, la data e l'ora della creazione.
          </li>
          <li>
            La storia evolve e cresce, diventando un romanzo collettivo.
          </li>
        </ul>
        <h2 className="unique-splash-subtitle-2">Cosa puoi fare?</h2>
        <ul className="unique-splash-list-2">
          <li>Leggere il capitolo iniziale e immergerti nell'avventura.</li>
          <li>Contribuire con le tue idee per sviluppare la trama.</li>
          <li>
            Osservare la mappa mentale che si espande, traccia delle vostre creazioni.
          </li>
        </ul>
        <p className="unique-splash-text-2">
          Unisciti a noi e scopri come andrà a finire MicroWavePocalypse, un romanzo scritto dagli utenti, per gli utenti!
        </p>

        {/* Pulsante di ingresso disabilitato se non si accettano le condizioni */}
        <button 
          className="unique-close-button-2" 
          onClick={onClose} 
          disabled={!acceptedTerms}
          style={{
            opacity: acceptedTerms ? 1 : 0.5,
            cursor: acceptedTerms ? 'pointer' : 'not-allowed'
          }}
        >
          Entra nel sito
        </button>

        {/* Sezione footer con link esterni */}
        <div className="unique-footer">
          <p className="unique-footer-text">
            ESTRO.GAMES - Tutti i diritti sono riservati
          </p>
          <div className="unique-footer-links">
            <a href="https://estro.games" target="_blank" rel="noopener noreferrer" className="unique-footer-link">ESTRO.GAMES</a> | 
            <a href="https://apollonephilim.it" target="_blank" rel="noopener noreferrer" className="unique-footer-link">APOLLO NEPHILIM</a>
          </div>
        </div>
      </div>

      {/* Modale delle Condizioni di Utilizzo */}
      <Modal 
        isOpen={isTermsModalOpen} 
        onRequestClose={closeTermsModal} 
        className="terms-modal" 
        overlayClassName="terms-overlay"
      >
        <div className="terms-modal-content">
          <h2>Condizioni di Utilizzo e Archiviazione dei Dati</h2>
          <p>
            Accettando i presenti termini, l'utente riconosce e accetta che il trattamento e l'archiviazione dei propri dati email avverranno al di fuori dell'Unione Europea, in conformità con le normative vigenti e alle politiche aziendali in materia di protezione dei dati. I dati raccolti consistono esclusivamente in indirizzi email utilizzati a scopo di login e non sono associati a informazioni personali identificabili o a persone fisiche.
          </p>
          <h3>1. Finalità del Trattamento</h3>
          <p>
            Gli indirizzi email forniti dagli utenti saranno utilizzati esclusivamente come metodo di accesso al sistema. Non verranno utilizzati per altri scopi, come profilazione, monitoraggio o invio di comunicazioni non richieste.
          </p>
          <h3>2. Archiviazione</h3>
          <p>
            Gli indirizzi email saranno archiviati su server situati al di fuori dell'Unione Europea, adottando misure di sicurezza adeguate per proteggere l'integrità delle informazioni. Non utilizziamo cookie né strumenti di tracciamento per raccogliere o monitorare l'attività degli utenti.
          </p>
          <h3>3. Esclusione di Responsabilità</h3>
          <p>
            L'utente accetta che la società non sarà ritenuta responsabile per eventuali violazioni o accessi non autorizzati ai dati, che possano derivare da attività illecite di terzi o eventi fuori dal controllo della società. L'utente esonera espressamente la società da qualsiasi responsabilità per danni diretti o indiretti derivanti dall'uso o dall'archiviazione dei propri indirizzi email al di fuori dell'Unione Europea.
          </p>
          <h3>4. Accettazione dei Termini</h3>
          <p>
            Continuando a utilizzare i nostri servizi, l'utente conferma di aver letto, compreso e accettato i presenti termini in relazione all'uso e all'archiviazione del proprio indirizzo email.
          </p>
          <button className="close-button" onClick={closeTermsModal}>
            Chiudi
          </button>
        </div>
      </Modal>
    </div>
  );
};

// Imposta lo stile della modale
Modal.setAppElement('#root');

export default SecondSplashScreen;
