// src/components/PasswordReset.js
import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const PasswordReset = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://microwavepocalypse.it/api/reset-password', { token, password });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore del server');
    }
  };

  return (
    <div>
      <h2>Reimposta Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Inserisci una nuova password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Reimposta</button>
      </form>
      <p>{message}</p>
    </div>
  );
};

export default PasswordReset;
