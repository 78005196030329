// src/components/PasswordResetRequest.js
import React, { useState } from 'react';
import axios from 'axios';

const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Aggiorna l'URL per allinearsi al backend
      const response = await axios.post('https://microwavepocalypse.it/api/send-reset-password', { email });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || 'Errore del server');
    }
  };

  return (
    <div>
      <h2>Richiedi Reset della Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Inserisci la tua email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Richiedi Reset</button>
      </form>
      <p>{message}</p>
    </div>
  );
};

export default PasswordResetRequest;
