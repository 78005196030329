import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const { token } = useParams(); // Cattura il token dalla URL
  const navigate = useNavigate(); // Per eventuali reindirizzamenti
  const [message, setMessage] = useState('Verificando il tuo indirizzo email...');
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`https://microwavepocalypse.it/api/verify-email/${token}`);
        setMessage(response.data); // "Email verificata con successo!"
        setIsVerified(true);
      } catch (error) {
        console.error('Errore durante la verifica dell\'email:', error.response?.data || error.message);
        setMessage(error.response?.data || 'Errore durante la verifica dell\'email.');
      }
    };

    verifyEmail();
  }, [token]);

  const handleGoToLogin = () => {
    navigate('/login'); // Reindirizza all'endpoint di login del frontend
  };

  return (
    <div style={styles.container}>
      <h1>Verifica Email</h1>
      <p>{message}</p>
      {isVerified && (
        <button onClick={handleGoToLogin} style={styles.button}>
          Vai al Login
        </button>
      )}
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
    padding: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    marginTop: '20px',
    cursor: 'pointer',
  },
};

export default VerifyEmail;
