import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './HomePage.css';
import ModalManager from './ModalManager';
import FlowManager from './FlowManager';
import { saveUsernameToLocalStorage, removeUsernameFromLocalStorage } from './localStorageHelper';
import { v4 as uuidv4 } from 'uuid';
import SplashScreen from './SplashScreen';
import jwt from 'jsonwebtoken'; 
import { handleSendVerificationEmail, handleRegister } from './ModalManager';

const HomePage = () => {
  // Configura l'intercettore Axios
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        handleAlertMessage('La sessione è scaduta. Sarai disconnesso.');
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        window.location.reload(); // Ricarica la pagina per aggiornare lo stato dell'app
      }
      return Promise.reject(error);
    }
  );

  const [dashModalOpen, setDashModalOpen] = useState(false); // Stato per la modale Dashboard
  const [inizioContent, setInizioContent] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [rectangleModalOpen, setRectangleModalOpen] = useState(false);
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [registerData, setRegisterData] = useState({ username: '', email: '', password: '' });
  const [userMessage, setUserMessage] = useState('');
  const [message, setMessage] = useState(''); // Definisci message come stato
  const [selectedResponse, setSelectedResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rectangles, setRectangles] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [selectedRectangle, setSelectedRectangle] = useState(null);
  const [userInfo, setUserInfo] = useState(null); // Stato per i dati utente
  const [verificationMessage, setVerificationMessage] = useState(''); // Definisci verificationMessage come stato
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userRectangles, setUserRectangles] = useState([]); // Stato per i rettangoli dell'utente
  const [alertMessage, setAlertMessage] = useState(''); // Stato per i messaggi di avviso

  // Funzione per gestire i messaggi di avviso
  const handleAlertMessage = (message) => {
    setAlertMessage(message);
    setTimeout(() => setAlertMessage(''), 5000); // Rimuove il messaggio dopo 5 secondi
  };

  // Recupera il token e lo userId dal local storage quando la pagina viene caricata
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUserId = localStorage.getItem('userId');
    if (storedToken && storedUserId) {
      setIsLoggedIn(true);
    }
  }, []);

  // Funzione per caricare i rettangoli dell'utente
  const loadUserRectangles = useCallback(async () => {
    try {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('Token mancante. L\'utente non è autenticato.');
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/user/user-rectangles`, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });

      setUserRectangles(response.data); // Salva i rettangoli nello stato
    } catch (error) {
      handleAlertMessage('Errore durante il caricamento dei rettangoli dell\'utente.'); // Mostra un avviso in caso di errore
      setUserRectangles([]); // In caso di errore, imposta un array vuoto
    }
  }, []);

  // Gestione clic sul cerchio
  const handleCircleClick = useCallback(() => {
    setModalIsOpen(true);
  }, []);

  // Gestione clic sul rettangolo per aprire la modale
  const handleRectangleClick = (rect) => {
    if (rect && rect.content) {
      setSelectedResponse(rect.content); // Imposta il contenuto della modale con i dettagli del rettangolo
      setSelectedRectangle(rect); // Imposta il rettangolo selezionato
      setRectangleModalOpen(true); // Apre la modale
    }
  };

  // Funzione di login
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/login`, loginData);
      setMessage(response.data.message);
      setIsLoggedIn(true);
      setLoginModalOpen(false);

      // Salva il token e userId nel localStorage
      localStorage.setItem('token', response.data.token);
      const decodedToken = jwt.decode(response.data.token); 
      localStorage.setItem('userId', decodedToken.userId); 

      localStorage.setItem('username', response.data.username); 
      localStorage.setItem('email', response.data.email);

      saveUsernameToLocalStorage(loginData.username);
    } catch (error) {
      handleAlertMessage(error.response?.data?.message || 'Errore del server'); // Messaggio di errore visibile
      console.error('Errore durante il login:', error.message);
    }
  };

  // Funzione di logout
  const handleLogout = () => {
    localStorage.removeItem('token'); 
    localStorage.removeItem('userId'); 
    localStorage.removeItem('username'); 
    localStorage.removeItem('email'); 
    removeUsernameFromLocalStorage();
    setIsLoggedIn(false);
    setMessage('Logout effettuato');
  };

  // Gestione clic sul bottone Dashboard per aprire la modale
  const handleDashboardClick = () => {
    setDashModalOpen(true);
  };

  // Funzione per gestire il click sul bottone "+" e generare un nuovo UUID
  const handleMessageClick = useCallback(() => {
    const storedUserId = localStorage.getItem('userId') || 'Utente sconosciuto'; 
    const storedUsername = localStorage.getItem('username') || 'Utente sconosciuto'; 

    const newRectangleId = `rect-${uuidv4()}`;

    const newRectangle = {
      user_id: storedUserId, 
      rectangle_id: newRectangleId, 
      content: 'In attesa di risposta...',
      filled: false,
      width: '200px',
      height: '100px',
      username: storedUsername, 
      createdAt: new Date().toISOString(),
    };

    setRectangles((prevRectangles) => [...prevRectangles, newRectangle]); 
    setSelectedRectangle(newRectangle); 
    setMessageModalOpen(true); 
  }, []);

  // Funzione per inviare il messaggio all'AI e aggiornare il rettangolo
  const handleSendMessage = async () => {
    setIsLoading(true);
    try {
      const storedUserId = localStorage.getItem('userId'); 
      const storedToken = localStorage.getItem('token'); 
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/send-message`, {
        input_text: userMessage,
      }, {
        headers: {
          Authorization: `Bearer ${storedToken}` 
        }
      });

      const fullContent = response.data.aiResponse;

      setRectangles((prevRectangles) =>
        prevRectangles.map((rect) =>
          rect.rectangle_id === selectedRectangle?.rectangle_id
            ? { ...rect, content: fullContent, filled: true }
            : rect
        )
      );

      await axios.post(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/rectangles`, {
        user_id: storedUserId, 
        rectangle_id: selectedRectangle?.rectangle_id,
        content: fullContent,
        filled: true,
        width: selectedRectangle?.width,
        height: selectedRectangle?.height,
      }, {
        headers: {
          Authorization: `Bearer ${storedToken}` 
        }
      });

      setConfirmationMessage('Messaggio inviato con successo!');  
      setIsLoading(false);
      setMessageModalOpen(false); 

      setTimeout(() => {
        setConfirmationMessage('');
      }, 3000); 
    } catch (error) {
      handleAlertMessage('Errore durante l\'invio del messaggio.'); // Messaggio di errore visibile
      console.error('Errore durante l\'invio del messaggio:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/inizio.txt')
      .then((response) => response.text())
      .then((text) => {
        const formattedText = text.replace(/\./g, '.\n');
        setInizioContent(formattedText);
      })
      .catch(error => console.error('Errore nel caricamento del file inizio:', error));

    axios.get(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/rectangles/load`)
      .then((response) => {
        setRectangles(response.data); 
      })
      .catch(error => handleAlertMessage('Errore nel recupero dei rettangoli.')); // Mostra un avviso in caso di errore
  }, []);

  const loadUserInfo = useCallback(async () => {
    try {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        throw new Error('Token mancante. L\'utente non è autenticato.');
      }
  
      // Modifica la chiamata con il percorso corretto
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || 'https://microwavepocalypse.it'}/api/user/user-info`, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      });
      
      
  
      setUserInfo(response.data);
    } catch (error) {
      handleAlertMessage('Errore durante il caricamento dei dati utente.'); // Mostra un avviso in caso di errore
      setUserInfo(null);
    }
  }, []);
  

  useEffect(() => {
      if (dashModalOpen) { 
        loadUserInfo();
        loadUserRectangles(); // Carica i rettangoli dell'utente quando la modale si apre
      }
    }, [dashModalOpen, loadUserInfo, loadUserRectangles]);

    return (
      <div className="home-page">
        <div className="sidebar">
          {isLoggedIn ? (
            <>
              <button className="sidebar-button" onClick={handleLogout}>Logout</button>
              <button className="sidebar-button" onClick={handleDashboardClick}>Dashboard</button> 
            </>
          ) : (
            <>
              <button className="sidebar-button" onClick={() => setLoginModalOpen(true)}>Login</button>
              <button className="sidebar-button" onClick={() => setRegisterModalOpen(true)}>Registrazione</button>
            </>
          )}
        </div>

        <div className="circle-node" onClick={handleCircleClick}>L'INIZIO</div>

        {isLoggedIn && (
          <button className="add-node-button" onClick={handleMessageClick}>+</button>
        )}

        {isLoading && <div className="loading-indicator">AI is processing...</div>}

        <FlowManager rectangles={rectangles} handleRectangleClick={handleRectangleClick} />

        <ModalManager
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          modalDashOpen={dashModalOpen} 
          setModalDashOpen={setDashModalOpen} 
          loginModalOpen={loginModalOpen}
          setLoginModalOpen={setLoginModalOpen}
          registerModalOpen={registerModalOpen}
          setRegisterModalOpen={setRegisterModalOpen}
          messageModalOpen={messageModalOpen}
          setMessageModalOpen={setMessageModalOpen}
          rectangleModalOpen={rectangleModalOpen}
          setRectangleModalOpen={setRectangleModalOpen}
          selectedResponse={selectedResponse} 
          setSelectedResponse={setSelectedResponse}
          inizioContent={inizioContent}
          handleLogin={handleLogin} 
          handleRegister={handleRegister} 
          handleSendMessage={handleSendMessage} 
          loginData={loginData}
          setLoginData={setLoginData}
          registerData={registerData}
          setRegisterData={setRegisterData}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
          isLoading={isLoading}
          dashModalOpen={dashModalOpen}
          setDashModalOpen={setDashModalOpen}
          message={message}
          setIsLoggedIn={setIsLoggedIn}
          handleSendVerificationEmail={handleSendVerificationEmail} 
          userInfo={userInfo} 
          setMessage={setMessage}
          setUserInfo={setUserInfo}
          verificationMessage={verificationMessage}
          setVerificationMessage={setVerificationMessage}
          userRectangles={userRectangles} 
          alertMessage={alertMessage} // Aggiunto alertMessage come prop
          handleAlertMessage={handleAlertMessage} // Aggiunta la funzione handleAlertMessage come prop
        />

        {confirmationMessage && (
          <div className="confirmation-message">
            {confirmationMessage}
          </div>
        )}

        {/* Messaggio di avviso, se presente */}
        {alertMessage && (
          <div className="alert-message">
            {alertMessage}
          </div>
        )}
      </div>
    );
};

export default HomePage;
