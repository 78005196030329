// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SplashScreen from './SplashScreen';
import HomePage from './HomePage';
import SecondSplashScreen from './SecondSplashScreen';
import PasswordReset from './components/PasswordReset';
import PasswordResetRequest from './components/PasswordResetRequest';
import VerifyEmail from './components/VerifyEmail';

function App() {
  const location = useLocation();
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [isSecondSplashVisible, setIsSecondSplashVisible] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith('/reset-password')) {
      setIsSplashVisible(false);
      setIsSecondSplashVisible(false);
    }
  }, [location]);

  const handleSplashClose = () => {
    setIsSplashVisible(false);
    setIsSecondSplashVisible(true);
  };

  const handleSecondSplashClose = () => {
    setIsSecondSplashVisible(false);
  };

  return (
    <div className="App">
      {isSplashVisible ? (
        <SplashScreen onClose={handleSplashClose} />
      ) : isSecondSplashVisible ? (
        <SecondSplashScreen onClose={handleSecondSplashClose} />
      ) : (
        <HomePage />
      )}
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <Routes>
        {/* Rotta per la verifica dell'email */}
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        
        {/* Rotta per richiedere il reset della password */}
        <Route path="/request-reset-password" element={<PasswordResetRequest />} />
        
        {/* Rotta per reimpostare la password con il token */}
        <Route path="/reset-password/:token" element={<PasswordReset />} />
        
        {/* Rotta principale che mostra SplashScreen o HomePage */}
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
  );
}
